import axios from 'axios';
import {
  ApiError,
  ForbiddenApiError,
  NoResponseApiError,
  NotFoundApiError,
  RequestCancelled,
  UnautorisedApiError,
  UnexpectedApiError,
  UnprocessableApiError,
  ValidationApiError,
  AlreadyExistsError,
} from './apiErrors';

const baseURL = process.env.VUE_APP_BASE_URL;
export const apiInstance = axios.create({
  baseURL,
});

apiInstance.interceptors.request.use(
  (config) => {
    // add token to all api requests
    if (
      window.store.state.auth
      && window.store.state.auth.user
      && window.store.state.auth.user.token
    ) {
      config.headers.token = window.store.state.auth.user.token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error)) {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 403: {
            window.store.dispatch('auth/logout');
            window.router.push({ name: 'login' });
            return Promise.reject(new ForbiddenApiError(error));
          }
          case 422: {
            // console.log(JSON.stringify(error.response));
            if (error.response.data.err.name === 'AlreadyExistsError') {
              return Promise.reject(new AlreadyExistsError(error));
            }
            return Promise.reject(new UnprocessableApiError(error));
          }
          case 404: {
            return Promise.reject(new NotFoundApiError(error));
          }
          case 409: {
            return Promise.reject(new ValidationApiError(error));
          }
          case 401: {
            window.store.dispatch('auth/logout');
            window.router.push({ name: 'login' });
            return Promise.reject(new UnautorisedApiError(error));
          }
          case 500: {
            return Promise.reject(new UnexpectedApiError(error));
          }
          default: {
            return Promise.reject(new ApiError(error));
          }
        }
      } else {
        return Promise.reject(new NoResponseApiError(error));
      }
    } else {
      return Promise.reject(new RequestCancelled(error));
    }
  },
);

export const LogIn = async (username, password) => {
  try {
    const result = await axios.post(`${baseURL}/v1/users/login`, {
      username,
      password,
    });
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), {
      status: err.response.status,
      errorMessage: err.response.data.err.name,
    });
  }
};

export const GetBikes = async (customerid, summary, lang) => {
  try {
    const result = await apiInstance.get(
      `/v1/items?summary=${summary}&language=${lang}&cid=${customerid}`,
    );
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const SearchItems = async (
  customerid,
  search,
  summary,
  filters,
  lang,
  options,
) => {
  const result = await apiInstance.post(
    `/v1/items/filter?summary=${summary}&language=${lang}&cid=${customerid}${search ? `&search=${search}` : ''}`,
    { filters: filters || [] },
    options,
  );
  return result.data;
};

export const GetFilterAttributes = async (
  customerid,
  search,
  filters,
  lang,
  options,
) => {
  const result = await apiInstance.post(
    `/v1/items/attributes?language=${lang}&cid=${customerid}${search ? `&search=${search}` : ''}`,
    { filters },
    options,
  );
  return result.data.result;
};

export const GetItemByid = async (ItemId, lang, customerId) => {
  const results = await Promise.all([
    apiInstance.get(`/v1/items/${ItemId}?language=${lang}`),
    apiInstance.get(`/v1/pricelists/${ItemId}/${customerId}?language=${lang}`),
  ]);
  return {
    ...results[0].data.result,
    $$price: {
      ...results[1].data.result,
    },
  };
};

export const GetItemByExternalId = async (externalItemId, lang, customerId) => {
  const itemDetailsResult = await apiInstance.get(`/v1/items/external/${externalItemId}?language=${lang}`);
  const itemId = itemDetailsResult.data.result.id;
  const itemPriceResult = await apiInstance.get(`/v1/pricelists/${itemId}/${customerId}?language=${lang}`);

  return {
    ...itemDetailsResult.data.result,
    $$price: {
      ...itemPriceResult.data.result,
    },
  };
};

export const GetCustomizerModel = async (configurationid) => {
  try {
    const result = await apiInstance.get(`/v1/customizer/model/${configurationid}`);
    return {
      ...result.data[0],
    };
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const GetRelatedItemsByMediaID = async (lang, customerId, mediaId) => {
  /*   var results = await Promise.all([
      apiInstance.get(`/v1/items/${mediaId}/relateditems?language=${lang}&cid=${customerId}`),
      apiInstance.get(`/v1/pricelists/media/${mediaId}/${customerId}`)
    ]);
    return {
      ...results[0].data.result,
      $$price: {
        ...results[1].data.result
      }
    }; */
  const result = await apiInstance.get(`/v1/items/${mediaId}/relateditems?language=${lang}&cid=${customerId}`);
  return result.data.result;
};

export const GetItemRelations = async (ItemId, lang, customerId, forPreorder = false) => {
  const response = await apiInstance.get(
    `/v1/items/${ItemId}/relations?language=${lang}&cid=${customerId}${forPreorder ? '&preorder=true' : ''}`,
  );
  return response.data.result;
};

export const GetItemRelationsExternal = async (externalItemId, lang, customerId) => {
  const response = await apiInstance.get(
    `/v1/items/external/${externalItemId}/relations?language=${lang}&cid=${customerId}`,
  );
  return response.data.result;
};

export const GetCustomerByid = async (CustomerId) => {
  try {
    const result = await apiInstance.get(`/v1/customers/${CustomerId}`);
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const generateKey = async (cid) => {
  try {
    const result = await apiInstance.post(`/v1/api/generate/key?cid=${cid}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const config = async (cid) => {
  try {
    const result = await apiInstance.post(`/v1/api/generate/key?cid=${cid}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const GetCustomerByUserId = async (userId) => {
  try {
    const result = await apiInstance.get(`/v1/users/${userId}/customers`);
    return result.data.result
      .sort((a, b) => {
        if (a.name < b.name) { return 1; }
        if (a.name > b.name) { return -1; }
        return 0;
      })
      .sort((x) => (!x.attributes.blocked ? -1 : 1));
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const GetCustomerByids = async (CustomerIds) => {
  try {
    const result = await Promise.all(
      CustomerIds.map((CustomerId) => GetCustomerByid(CustomerId)),
    );
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const GetOrders = async (customerid) => {
  const result = await apiInstance.get(`/v1/orders?cid=${customerid}`);
  const data = result.data.result.map((o) => ({
    ...o,
    orderlinedescription: o.orderlines.map((ol) => ol.description),
    allserials: o.orderlines.map((ol) => (ol.serials ? ol.serials.join(' ') : null)),
  }));
  return { data, excel: result.data.excel };
};

export const GetBackOrders = async (customerid) => {
  const result = await apiInstance.get(`/v1/backorders?cid=${customerid}`);
  return { data: result.data.backorders, excel: result.data.excel };
};

export const GetVouchers = async (active) => {
  const tempResult = await apiInstance.get(`/v1/discounts?active=${active}`);
  /*   tempResult.data.forEach((r) => {
    if (!('options' in r.discount[0])) {
      r.discount[0].options = { cumulative: null, inventoryOnly: null };
    }
  }); */
  const result = tempResult;
  return result.data;
};

export const GetNavisionOrders = async (customerid) => {
  try {
    const result = await apiInstance.get(`/v1/navorders?cid=${customerid}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const getOrderDetail = async (customerid, oid) => {
  const oidStr = `${oid}`;
  try {
    let result = '';
    // search if order id(oid) string contains SO(case insensitive)
    if (oidStr.search(/so/i) === -1) {
      result = await apiInstance.get(
        `/v1/orderdetail?cid=${customerid}&oid=${oidStr}`,
      );
    } else {
      result = await apiInstance.get(
        `/v1/navorderdetail?cid=${customerid}&oid=${oidStr}`,
      );
    }
    if (result.data.result.length === 0) {
      throw new Error('UnexpectedError');
    }
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const getInvoiceDetail = async (customerid, id) => {
  try {
    const result = await apiInstance.get(
      `/v1/navinvoicedetail?cid=${customerid}&id=${id}`,
    );
    if (result.data.result.length === 0) {
      throw new Error('UnexpectedError');
    }
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const GetNavisionInvoices = async (customerid) => {
  try {
    const result = await apiInstance.get(`/v1/invoices?cid=${customerid}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const RfcLocation = async (rfcLocationObj) => {
  try {
    const result = await apiInstance.post(
      '/v1/customers/locations',
      rfcLocationObj,
    );
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const RfcCustomer = async (rfcCustomerObj) => {
  try {
    const result = await apiInstance.post(
      '/v1/customers/company',
      rfcCustomerObj,
    );
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const downloadSignedUrl = async (cid, fileKey, type) => {
  try {
    const result = await apiInstance.get(
      `/v1/downloadSignedUrl?cid=${cid}&fileKey=${fileKey}&type=${type}`,
    );
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const download = async (cid, fileKey, type) => {
  try {
    const result = await apiInstance.get(
      `/v1/download?cid=${cid}&fileKey=${fileKey}&type=${type}`,
      { responseType: 'arraybuffer' },
    );
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const placeOrder = async (order) => {
  try {
    const result = await apiInstance.post('/v1/orders', order);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

function cleanOrderReferenceItems(items) {
  // Filters out update-items without any info in them
  return (items || []).filter((x) => (x.relationId || x.phone || x.email || x.firstName || x.lastName || x.note || x.quantity))
    .map(
      (x) => ({
        orderline: x.orderline,
        index: x.index,
        serial: x.serial,
        relationId: x.relationId,
        firstName: x.firstName,
        lastName: x.lastName,
        email: x.email,
        phone: x.phone,
        note: x.note,
        quantity: x.quantity || 1,
      }),
    );
}

/**
 * No longer used, replaced by updateNavOrderReference and UpdateOrderlineReferences as functionality was moved to
 * seperated dialogs with their own 'save' functionality
 */
export const updateNavOrder = async (externalorderid, reference, items) => {
  try {
    // create record in table navorderupdate
    const navOrderUpdate = {
      externalorderid,
      reference,
      items: cleanOrderReferenceItems(items),
    };
    const result = await apiInstance.post('/v1/createnavorderupdate', navOrderUpdate);
    return result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const updateNavOrderReference = async (externalorderid, reference) => {
  try {
    // create record in table navorderupdate
    const navOrderUpdate = {
      externalorderid,
      reference,
    };
    const result = await apiInstance.post('/v1/updateorderreference', navOrderUpdate);
    return result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const getAttributeTranslations = async (lang) => {
  const result = await apiInstance.get(
    `/v1/items/attributetranslations?language=${lang}`,
  );
  return result.data.result;
};

export const getContracts = async (cid) => {
  try {
    const result = await apiInstance.get(`/v1/contracts?cid=${cid}`);
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const getMediafeed = async (customerid, language, searchVal) => {
  try {
    let result = {};
    if (!searchVal) {
      result = await apiInstance.get(
        `/v1/media?cid=${customerid}&language=${language}`,
      );
    } else {
      result = await apiInstance.get(
        `/v1/media?cid=${customerid}&language=${language}&searchVal=${searchVal}`,
      );
    }
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const getMediafeedById = async (id, language) => {
  try {
    const result = await apiInstance.get(
      `/v1/media/${id}?language=${language}`,
    );
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const getDhlTrackingInformation = async (trackingcode) => {
  try {
    const result = await axios.get(`https://api-gw.dhlparcel.nl/track-trace?key=${trackingcode}`);
    let statuses = result.data[0].view.phaseDisplay;
    statuses = statuses.map((status) => {
      const events = result.data[0].events.filter((e) => e.category === status.phase);
      const lastEvent = events[events.length - 1];
      return { ...status, events, lastEvent };
    });
    return {
      statuses,
      current: statuses.slice().reverse().find((o) => o.events.length > 0),
      code: result.data[0].barcode,
    };
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), {
      status: err.response.status,
      errorMessage: err.response.data.err.name,
    });
  }
};

export const generateCustomizerWithModelAndSelections = async (model, selections, lang, customerId) => {
  try {
    const result = await apiInstance.post(
      `/v1/customizer/generate?language=${lang}&cus=${customerId}`,
      { model, selections },
    );
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const getDealerStats = async ({
  customerId, locationId, type, distance, range, granularity, lat, lng,
}) => {
  try {
    let result = '';

    const queryParams = {
      cid: customerId,
      distance,
      range: (range || []).map((x) => x.toISOString()).join(';'),
      granularity: granularity || 'day',
    };
    if (locationId) {
      queryParams.lid = locationId;
    } else if (lat && lng) {
      queryParams.lat = lat;
      queryParams.lng = lng;
    }

    // cid=${customerId}&lid=${locationId}&distance=${distance}&range=${decodeURIComponent((range || []).map((x) => x.toISOString()).join(';'))}&granularity=${granularity || 'day'}
    result = await apiInstance.get(
      `/v1/stats/visited/${type}?${Array.from(Object.entries(queryParams)).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')}`,
    );

    const parseDate = (datestr) => {
      if (!datestr) return datestr;
      const split = datestr.split('-').map((x) => parseInt(x, 10));
      return new Date(split[0], split[1] - 1, split[2]);
    };

    return result.data.map((d) => ({
      ...d,
      data: d.data.map((dp) => ({
        ...dp,
        date: parseDate(dp.date),
      })),
      count: d.data.map((dp) => dp.count).reduce((a, b) => a + b, 0),
    })).sort((a, b) => b.count - a.count);
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const searchAddress = async ({
  q,
}) => {
  try {
    const result = await apiInstance.get(
      `/v1/stats/custom-location/search?q=${encodeURIComponent(q)}`,
    );

    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const resolveAddress = async ({
  lat, lng,
}) => {
  try {
    let result = null;
    const queryParams = {};
    if (lat && lng) {
      queryParams.lat = lat;
      queryParams.lng = lng;
    } else {
      throw new Error('No lat/lng specified');
    }
    result = await apiInstance.get(
      `/v1/stats/custom-location/resolve?${Array.from(Object.entries(queryParams)).map(
        ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
      ).join('&')}`,
    );

    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const GetStock = async (customerid) => {
  const result = await apiInstance.get(`/v1/stock?cid=${customerid}`);
  return result.data.result;
};

export const SearchNewStock = async (customerid, search, customerType) => {
  const queryParams = Array.from(Object.entries({
    cid: customerid,
    q: search || undefined,
    type: customerType,
  }))
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  const result = await apiInstance.get(`/v1/stock/add/search?${queryParams.join('&')}`);
  return result.data.result.map((r) => ({
    ...r,
    shipped: r.shipped ? new Date(r.shipped) : r.shipped,
    orderdate: r.orderdate ? new Date(r.orderdate) : r.orderdate,
  }));
};

export const AddNewStock = async (customerid, request) => {
  const result = await apiInstance.post('/v1/stock/', {
    ...request,
    customerid,
  });
  console.log(result);
  return result;
};

export const AddChangeDesignRequest = async (request) => {
  const result = await apiInstance.post('/v1/customizer/savedesignchange', {
    ...request,
  });
  console.log(result);
  return result;
};

export const PatchStock = async (customerid, serial, changes) => {
  const result = await apiInstance.patch(`/v1/stock/${encodeURIComponent(serial)}?cid=${customerid}`, changes);
  return result;
};

export const ConfirmOutdatedStock = async (customerid, serials) => {
  const result = await apiInstance.post(`/v1/stock/confirm?cid=${customerid}`, { serials });
  return result;
};

export const UpdateCustomerAddressInfo = async (customerid, locationId, info) => {
  try {
    const result = await apiInstance.patch(
      `/v1/customers/${customerid}/locations/${locationId}/info`,
      info,
    );
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const UploadCustomerAddressImage = async (customerid, locationId, type, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    const result = await apiInstance.post(
      `/v1/customers/${customerid}/locations/${locationId}/upload`,
      formData,
    );
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const UpdateCustomerDeliveryInfo = async (customerid, locationId, info) => {
  try {
    const result = await apiInstance.patch(
      `/v1/customers/${customerid}/locations/${locationId}/delivery`,
      info,
    );
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const UploadCustomerStockImage = async (customerid, file, serial) => {
  try {
    const queryParams = Array.from(Object.entries({
      cid: customerid,
      serial: serial || undefined,
    }))
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);

    const formData = new FormData();
    formData.append('file', file);
    const result = await apiInstance.post(
      `/v1/stock/image/upload?${queryParams.join('&')}`,
      formData,
    );
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const getExports = async (cid, lang) => {
  try {
    const result = await apiInstance.get(`/v1/exports?cid=${cid}&language=${lang}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const SearchOwnItems = async (customerid, {
  search,
  limit,
  exclude,
}) => {
  const queryParams = Array.from(Object.entries({
    q: search || undefined,
    exclude: exclude ? exclude.join(',') : undefined,
    limit,
  }))
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  console.log('Parameters');
  console.log(queryParams);
  const result = await apiInstance.get(`/v1/customers/${customerid}/items/search?${queryParams.join('&')}`);
  return result.data.result.map((r) => ({
    ...r,
    shipped: r.shipped ? new Date(r.shipped) : r.shipped,
    orderdate: r.orderdate ? new Date(r.orderdate) : r.orderdate,
  }));
};

// CRM
export const GetCustomerRelations = async (customerid, {
  search,
  limit,
} = {}) => {
  const queryParams = Array.from(Object.entries({
    cid: customerid,
    q: search || undefined,
    limit,
  }))
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  const result = await apiInstance.get(`/v1/crm/relations?${queryParams.join('&')}`);
  return result.data.result;
};

export const GetCustomerRelation = async ({ cid, rid }) => {
  const result = await apiInstance.get(`/v1/crm/relations/${rid}?cid=${cid}`);
  return result.data.result;
};

export const UpdateCustomerRelation = async ({ cid, rid }, relation) => {
  const result = await apiInstance.put(`/v1/crm/relations/${rid}?cid=${cid}`, relation);
  return result.data.result;
};

export const AssignToCustomerRelation = async ({ cid, rid }, payload) => {
  const result = await apiInstance.post(`/v1/crm/relations/${rid}/assign?cid=${cid}`, payload);
  return result.data.result;
};
export const UnassignFromCustomerRelation = async ({ cid, rid }, payload) => {
  const result = await apiInstance.post(`/v1/crm/relations/${rid ?? null}/unassign?cid=${cid}`, payload);
  return result.data.result;
};

export const AddNewCustomerRelation = async (customerid, relation) => {
  const result = await apiInstance.post(`/v1/crm/relations?cid=${customerid}`, relation);
  return result.data.result;
};

export const DeleteCustomerRelation = async ({ cid, rid }) => {
  const result = await apiInstance.delete(`/v1/crm/relations/${rid}?cid=${cid}`);
  return result.data.result;
};

export const InviteCustomerRelationToB2C = async ({ cid, rid }) => {
  const result = await apiInstance.post(`/v1/crm/relations/${rid}/invite?cid=${cid}`, {
    // No parameters to be given for now...
  });
  return result.data.result;
};

export const UpdateOrderlineReferences = async ({ cid }, orderReferenceUpdates) => {
  try {
    const result = await apiInstance.post(`/v1/crm/update-orderline-references?cid=${cid}`, orderReferenceUpdates);
    return result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const QueryAssignableItems = async (customerid, {
  search,
  limit,
  exclude,
  filters,
  link,
}) => {
  const queryParams = Array.from(Object.entries({
    cid: customerid,
    q: search || undefined,
    exclude: exclude ? exclude.join(',') : undefined,
    limit,
    filters: filters ? JSON.stringify(filters) : undefined,
    link,
  }))
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  const result = await apiInstance.get(`/v1/crm/search-assignable?${queryParams.join('&')}`);
  return result.data.result.map((r) => ({
    ...r,
  }));
};

export const UpdateVoucher = async (voucher) => {
  try {
    const result = await apiInstance.put(
      '/v1/discount',
      voucher,
    );
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};

export const CreateVoucher = async (voucher) => {
  try {
    const result = await apiInstance.post(
      '/v1/discount',
      voucher,
    );
    return result;
  } catch (err) {
    throw Object.assign(new Error('UnexpectedError'), { status: 500 });
  }
};
export const GetBrands = async () => {
  const result = await apiInstance.get('/v1/discount/brands');
  return result.data;
};

export const GetRanges = async () => {
  const result = await apiInstance.get('/v1/discount/ranges');
  return result.data;
};
export const GetFamilies = async () => {
  const result = await apiInstance.get('/v1/discount/families');
  return result.data;
};
export const GetCategories = async () => {
  const result = await apiInstance.get('/v1/discount/categories');
  return result.data;
};
export const GetVoucher = async (name) => {
  const result = await apiInstance.get(`/v1/discount/${name.toUpperCase()}`);
  return result.data;
};
export const Generate = async () => {
  const result = await apiInstance.get('/v1/discount/generate');
  return result.data.code;
};

export const getPrebookConfig = async () => {
  const result = await apiInstance.get('/v1/params/prebook_config');
  return JSON.parse(result.data?.prebook_config || '{}');
};

export const getPrebookItems = async (lang, customerid, brand, abort) => {
  const result = await apiInstance.get(`/v1/items/preorder?language=${lang}&cid=${customerid}${brand ? `&brand=${brand}` : ''}`, {
    signal: abort,
  });
  return result.data;
};

export const getPrebookExports = async (customerid, year, brand) => {
  const result = await apiInstance.get(`/v1/prebook/${customerid}/${year}/${brand}/exports`);
  return result.data;
};

export const getPrebook = async (customerid, year, brand) => {
  const result = await apiInstance.get(`/v1/prebook/${customerid}/${year}/${brand}/preorder`);
  return result.data;
};

export const getForecast = async (customerid, year, brand) => {
  const result = await apiInstance.get(`/v1/prebook/${customerid}/${year}/${brand}/forecast`);
  return result.data;
};

// Before the users closes the browser tab / website, we save the latest state of the preorder to the back-end.
// This can be done with sendBeacon (POST only), but since we need to add the token in the header we must use fetch with keepalive.
// Until now this is not supported by Axios, therefore we use the native fetch function.
export const savePrebook = async (customerid, year, status, prevStatus, preorder, reason, remarks, brand, openToCustomer) => {
  const token = window?.store?.state?.auth?.user?.token;
  if (!token) return;
  fetch(`${baseURL}/v1/prebook/${customerid}/${year}/${brand}/preorder`, {
    method: 'POST',
    keepalive: true,
    headers: {
      'Content-Type': 'application/json',
      token,
    },
    body: JSON.stringify({
      preorder, preorder_status: status, previous_status: prevStatus, remarks, reason, openToCustomer,
    }),
  });
};

export const saveForecast = async (customerid, year, forecast, brand) => {
  const result = await apiInstance.post(`/v1/prebook/${customerid}/${year}/${brand}/forecast`, { forecast });
  return result.data;
};

export const getLTM = async (customerid, brand, lang) => {
  const result = await apiInstance.get(`/v1/prebook/${customerid}/${brand}/ltm?language=${lang}`);
  return result.data;
};

export const getHistory = async (customerid, year, brand, lang) => {
  const result = await apiInstance.get(`/v1/prebook/${customerid}/${year}/${brand}/history?language=${lang}`);
  return result.data;
};

export const changePrebookBike = async (customerid, orderid, externalorderid, orderline, orderlineitemindex, externalidfrom, externalidto, iteminfo, reference) => {
  try {
    // create record in table navorderupdate
    const updates = {
      customerid,
      orderid,
      externalorderid,
      orderline,
      orderlineitemindex,
      externalidfrom,
      externalidto,
      iteminfo,
      reference,
    };
    const result = await apiInstance.post(`/v1/orders/${orderid}/prebookchanges/`, updates);
    return result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};
